<template>
  <div>
    <v-card tile flat>
      <v-toolbar dense flat color="grey lighten-3">
        <v-toolbar-title>
          {{ $t("pageHeader.createUser") }}
        </v-toolbar-title>
        <v-spacer> </v-spacer>
        <span class="mr-2">{{ $t("labels.active") }}</span>
        <toggle-button
          :labels="true"
          v-model="User.active"
          :value="User.active"
          :sync="true"
        >
        </toggle-button>
      </v-toolbar>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="12" md="2">
            <v-row justify="start">
              <v-col cols="12">
                <img
                  class="br-50"
                  width="140"
                  height="160"
                  v-if="imageUrl"
                  :src="imageUrl"
                  alt="User"
                />
                <img
                  class="br-50"
                  width="140"
                  height="160"
                  v-else
                  src="@/assets/images/user.jpeg"
                  alt="User"
                />
              </v-col>
              <v-col cols="12">
                <v-row class="pa-0">
                  <v-col class="shrink">
                    <v-btn fab color="red">
                      <v-icon color="white">delete</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col class="shrink">
                    <v-btn fab color="info" @click="pickFile">
                      <v-icon color="white">attach_file </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <input
              type="file"
              ref="image"
              style="display: none"
              accept="image/*"
              @change="onFilePicked"
            />
          </v-col>
          <v-col class="ml-2" hidden-xs-only>
            <h1 class="display-1 font-weight-regular">{{ User.name }}</h1>
            <h2 class="title font-weight-light">{{ User.email }}</h2>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-divider></v-divider>
    <v-container fluid grid-list-lg>
      <v-row>
        <v-col cols="12" sm="12" md="6">
          <v-card outlined>
            <v-toolbar dense flat color="grey lighten-3">
              <v-toolbar-title>
                <span>{{ $t("pageHeader.userInformation") }}</span>
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <toggle-button
                :width="70"
                :labels="{ checked: 'Admin', unchecked: 'User' }"
                v-model="User.isAdmin"
                :value="User.isAdmin"
                :sync="true"
              >
              </toggle-button>
            </v-toolbar>
            <v-card-text>
              <v-row>
                <v-col cols="12" sm="12">
                  <v-text-field
                    :error-messages="nameErrors"
                    v-model="User.name"
                    label="Full name"
                    outlined
                    dense
                    :hide-details="nameErrors.length === 0"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    :error-messages="emailErrors"
                    label="Login Email"
                    outlined
                    dense
                    :hide-details="emailErrors.length === 0"
                    v-model="User.email"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    :error-messages="passwordErrors"
                    v-model="User.password"
                    outlined
                    dense
                    :hide-details="passwordErrors.length === 0"
                    label="Login Password"
                    type="password"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    label="Phone Number"
                    outlined
                    dense
                    hide-details
                    v-model="User.phoneNumber"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="12" sm12>
                  <v-text-field
                    outlined
                    dense
                    hide-details
                    label="Address"
                    v-model="User.address"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="12" sm4>
                  <v-text-field
                    outlined
                    dense
                    hide-details
                    label="City"
                    v-model="User.city"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="12" sm4>
                  <v-text-field
                    outlined
                    dense
                    hide-details
                    label="State"
                    v-model="User.state"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="12" sm4>
                  <v-text-field
                    outlined
                    dense
                    hide-details
                    label="Zipcode"
                    v-model="User.zip"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="12" md="6">
          <v-card outlined>
            <v-toolbar dense flat color="grey lighten-3">
              <v-toolbar-title>
                <span
                  ><span class="green--text">{{ selected.length }}</span> /
                  <span class="blue--text">{{ rights.length }}</span></span
                >

                <span class="ml-1">{{ $t("pageHeader.userRights") }}</span>
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn small text color="success" @click="allRights"
                >give all rights</v-btn
              >
            </v-toolbar>
            <v-card-text>
              <v-text-field
                v-model="search"
                outlined
                class="mb-2"
                prepend-inner-icon="search"
                rounded
                dense
                label="Search"
                single-line
                hide-details
                clearable
              ></v-text-field>
              <v-data-table
                v-model="selected"
                :headers="headers"
                :items="rights"
                flat
                :search="search"
                item-key="name"
                show-select
              >
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-divider></v-divider>
    <v-card tile flat>
      <v-card-actions>
        <v-btn color="blue darken-1" text @click="cancel">Cancel</v-btn>
        <v-spacer> </v-spacer>
        <v-btn color="blue darken-1" dark @click="save">Save</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import userService from "../service.js";

const { validationMixin } = require("vuelidate");
const { required, minLength, email } = require("vuelidate/lib/validators");

export default {
  name: "create-User",
  data() {
    return {
      search: null,
      selected: [],
      imageName: "",
      imageUrl: "",
      imageFile: "",
      isLoading: true,
      headers: [
        {
          text: "Access",
          align: "start",
          sortable: false,
          value: "name",
        },
        { text: "Type", value: "Type.name" },
      ],
      User: {
        name: null,
        phoneNumber: null,
        email: null,
        password: null,
        address: null,
        isAdmin: false,
        city: null,
        state: null,
        zip: null,
        active: true,
        userRights: null,
        imageUrl: null,
        image: null,
      },
      rights: [],
    };
  },
  watch: {
    imageUrl(val) {
      this.User.image = val;
    },
  },
  async created() {
    await this.getAllUserRights();
    this.isLoading = false;
  },
  mixins: [validationMixin],
  validations: {
    User: {
      name: {
        required,
        minLength: minLength(2),
      },
      password: {
        required,
        minLength: minLength(4),
      },
      email: {
        required,
        email,
      },
    },
  },
  computed: {
    ...mapGetters("global", ["currentUser"]),
    passwordErrors() {
      const errors = [];
      if (!this.$v.User.password.$dirty) return errors;
      if (!this.$v.User.password.required)
        errors.push(this.$t("validations.fieldIsRequired"));
      if (!this.$v.User.password.minLength)
        errors.push(this.$t("validations.fieldMustbeatLeast4ChrLong"));
      return errors;
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.User.name.$dirty) return errors;
      if (!this.$v.User.name.required)
        errors.push(this.$t("validations.fieldIsRequired"));
      if (!this.$v.User.name.minLength)
        errors.push(this.$t("validations.fieldMustbeatLeast4ChrLong"));
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.User.email.$dirty) return errors;
      if (!this.$v.User.email.required)
        errors.push(this.$t("validations.fieldIsRequired"));
      if (!this.$v.User.email.email)
        errors.push(this.$t("validations.emailIsNotValid"));
      return errors;
    },
    assignedRights() {
      var temp = this.rights.filter((rights) => rights.assigned == true);
      return temp.length;
    },
    progress() {
      return (this.assignedRights / this.rights.length) * 100;
    },
    unassignedRights() {
      return this.rights.length - this.assignedRights;
    },
  },
  methods: {
    allRights() {
      this.selected = [];
      this.selected = this.rights;
    },
    pickFile() {
      this.$refs.image.click();
    },
    onFilePicked(e) {
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.imageName = files[0].name;
        if (this.imageName.lastIndexOf(".") <= 0) {
          return;
        }
        const fr = new FileReader();
        fr.readAsDataURL(files[0]);
        fr.addEventListener("load", () => {
          this.imageUrl = fr.result;
          this.imageFile = files[0]; // this is an image file that can be sent to server...
        });
      } else {
        this.imageName = "";
        this.imageFile = "";
        this.imageUrl = "";
      }
    },
    cancel() {
      this.$router.go(-1);
    },
    getAllUserRights() {
      return userService.getAllUserRights().then((result) => {
        this.rights = result.data;
      });
    },
    save() {
      const newRights = [];
      this.selected.map((x) => {
        newRights.push(x.number);
      });
      this.User.userRights = newRights.toString();
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$swal
          .mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          })
          .fire({
            icon: "error",
            title: this.$t("toastMessage.text.invalidFields"),
          });
      } else {
        // console.log("this.result before");
        return userService
          .create(this.User)
          .then(() => {
            // console.log("this.result", result);
            this.$swal
              .mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
              })
              .fire({
                icon: "success",
                title: "User created!",
              });
            this.cancel();
          })
          .catch((err) => {
            // console.log("err", err);
            this.$swal
              .mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
              })
              .fire({
                icon: "error",
                title: err.data.message,
              });
          });
      }
    },
  },
};
</script>

<style>
.imgSize {
  width: 120px;
  height: 180px;
}
</style>
