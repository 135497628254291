var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":"fluid","grid-list-lg":""}},[_c('v-card',{attrs:{"loading":_vm.loading,"outlined":""}},[_c('ListTitle',{attrs:{"showBackButton":true,"access":_vm.checkRightStatus(4),"title":"User"},on:{"add":_vm.add}}),_c('v-card-title',[_c('v-flex',{attrs:{"xs12":"","sm4":""}},[_c('v-text-field',{attrs:{"outlined":"","prepend-inner-icon":"search","rounded":"","dense":"","label":"Search","single-line":"","hide-details":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"item-key":"userID","items":_vm.users,"search":_vm.search,"loading":_vm.loading,"sort-by":_vm.sortBy,"page":_vm.page,"items-per-page":_vm.itemsPerPage},on:{"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',{staticClass:"text-left"},[_c('span',[_vm._v(_vm._s(item.name)+" "),(item.superAdmin)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"amber accent-4"}},on),[_vm._v("mdi-crown")])]}}],null,true)},[_c('span',[_vm._v("Super Admin")])]):_vm._e(),(item.isAdmin && !item.superAdmin)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"green accent-4"}},on),[_vm._v("mdi-account-circle")])]}}],null,true)},[_c('span',[_vm._v("Admin Account")])]):_vm._e()],1)]),_c('td',{staticClass:"text-left"},[_vm._v(_vm._s(item.email))]),_c('td',{staticClass:"text-left"},[_vm._v(_vm._s(item.phoneNumber))]),_c('td',{staticClass:"justify-end align-center text-right"},[_c('v-menu',{attrs:{"bottom":"","left":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("more_vert")])],1)]}}],null,true)},[_c('v-list',{staticClass:"menu-button"},[(_vm.checkRightStatus(29))?_c('v-list-item',{on:{"click":function($event){return _vm.edit(item)}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("buttons.edit")))])],1):_vm._e(),(_vm.checkRightStatus(28) && !item.superAdmin)?_c('v-list-item',{on:{"click":function($event){return _vm.deleteUser(item)}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("buttons.delete"))+" ")])],1):_vm._e()],1)],1)],1)])]}}])},[_c('v-alert',{attrs:{"slot":"no-results","value":true,"color":"error","icon":"warning"},slot:"no-results"},[_vm._v("Your search for \""+_vm._s(_vm.search)+"\" found no results.")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }