<template>
  <v-container fluid="fluid" grid-list-lg>
    <v-card :loading="loading" outlined>
      <ListTitle
        :showBackButton="true"
        :access="checkRightStatus(4)"
        title="User"
        @add="add"
      />
      <v-card-title>
        <v-flex xs12 sm4>
          <v-text-field
            v-model="search"
            outlined
            prepend-inner-icon="search"
            rounded
            dense
            label="Search"
            single-line
            hide-details
            clearable
          ></v-text-field>
        </v-flex>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          item-key="userID"
          :items="users"
          :search="search"
          :loading="loading"
          :sort-by="sortBy"
          :page.sync="page"
          :items-per-page="itemsPerPage"
        >
          <template v-slot:item="{ item }">
            <tr>
              <td class="text-left">
                <span
                  >{{ item.name }}
                  <v-tooltip bottom v-if="item.superAdmin">
                    <template v-slot:activator="{ on }">
                      <v-icon color="amber accent-4" v-on="on"
                        >mdi-crown</v-icon
                      >
                    </template>
                    <span>Super Admin</span>
                  </v-tooltip>
                  <v-tooltip bottom v-if="item.isAdmin && !item.superAdmin">
                    <template v-slot:activator="{ on }">
                      <v-icon color="green accent-4" v-on="on"
                        >mdi-account-circle</v-icon
                      >
                    </template>
                    <span>Admin Account</span>
                  </v-tooltip>
                </span>
              </td>
              <td class="text-left">{{ item.email }}</td>
              <td class="text-left">{{ item.phoneNumber }}</td>
              <td class="justify-end align-center text-right">
                <v-menu bottom left offset-y>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" icon>
                      <v-icon>more_vert</v-icon>
                    </v-btn>
                  </template>
                  <v-list class="menu-button">
                    <v-list-item
                      v-if="checkRightStatus(29)"
                      @click="edit(item)"
                    >
                      <v-list-item-title>{{
                        $t("buttons.edit")
                      }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      v-if="checkRightStatus(28) && !item.superAdmin"
                      @click="deleteUser(item)"
                    >
                      <v-list-item-title
                        >{{ $t("buttons.delete") }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </template>
          <v-alert slot="no-results" :value="true" color="error" icon="warning"
            >Your search for "{{ search }}" found no results.</v-alert
          >
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import userService from "../service.js";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      user: null,
      headers: [
        {
          text: this.$t("labels.name"),
          value: "name",
          sortable: true,
        },
        {
          text: this.$t("labels.emailAddress"),
          value: "email",
          sortable: true,
        },
        {
          text: this.$t("labels.phoneNumber"),
          value: "phoneNumber",
          sortable: true,
        },
        {
          text: "",
          value: "action",
          sortable: false,
        },
      ],
      search: null,
      users: [],
      loading: true,
      page: 1,
      sortBy: "balance",
      pageCount: 0,
      itemsPerPage: 10,
    };
  },
  computed: {
    ...mapGetters("global", ["currentUser", "checkRightStatus"]),
  },
  async created() {
    await this.getList();
    document.title = "smplsale - User List";
  },
  methods: {
    deleteUser(user) {
      if (!user.superAdmin) {
        if (
          user.id === this.currentUser.id ||
          this.currentUser.superAdmin ||
          this.currentUser.isAdmin
        ) {
          this.removeUser(user);
        } else {
          this.$swal(
            "Delete User",
            "You are not allow to delete other user account",
            "error"
          );
        }
      } else {
        if (this.currentUser.superAdmin) {
          this.removeUser(user);
        } else {
          this.$swal("Delete User", "Super admin can't be delete", "error");
        }
      }
    },
    removeUser(user) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          this.$Progress.start();
          return userService
            .delete(user.id)
            .then((response) => {
              if (response.data) {
                this.$swal("Deleted!", "User has been deleted.", "success");
                this.$Progress.finish();
                this.getList();
              }
            })
            .catch((error) => {
              this.$swal
                .mixin({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                })
                .fire({
                  icon: "error",
                  title: error.data.message,
                });
              this.$Progress.fail();
            });
        }
      });
    },
    add() {
      this.$router.push({ path: `/user/create` });
    },
    edit(user) {
      if (!user.superAdmin) {
        if (
          user.id === this.currentUser.id ||
          this.currentUser.superAdmin ||
          this.currentUser.isAdmin
        ) {
          this.$router.push({ path: `/user/edit/${user.id}` });
        } else {
          this.$swal(
            "Edit User",
            "You are not allow to edit someone else account",
            "error"
          );
        }
      } else {
        if (this.currentUser.superAdmin) {
          this.$router.push({ path: `/user/edit/${user.id}` });
        } else {
          this.$swal(
            "Edit User",
            "Super admin account can be only edit by super admin.",
            "error"
          );
        }
      }
    },
    getList() {
      this.loading = true;
      userService.getAll().then((response) => {
        // console.log("users", response);
        this.users = response.data;
        this.loading = false;
      });
    },
  },
};
//have removed assignedUser
</script>
