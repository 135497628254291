<template>
  <v-dialog v-model="dialog" width="350">
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" color="pink" text>Change Password</v-btn>
    </template>

    <v-card>
      <ModelTitle title="Change Password" @close="close()" />

      <!-- <v-toolbar dark color="primary">
        <v-toolbar-title>Change Password</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="close()">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar> -->

      <v-card-text class="mt-10">
        <v-text-field v-if="!currentUser.superAdmin" v-model="oldPassword" outlined dense
          :append-icon="showOldPassword ? 'visibility_off' : 'visibility'" :type="showOldPassword ? 'text' : 'password'"
          label="Old Password" :error-messages="oldPasswordErrors" counter="counter"
          @click:append="showOldPassword = !showOldPassword">
        </v-text-field>

        <v-text-field v-model="newPassword" outlined dense
          :append-icon="showNewPassword ? 'visibility_off' : 'visibility'" :type="showNewPassword ? 'text' : 'password'"
          label="New Password" :error-messages="newPasswordErrors" counter="counter"
          @click:append="showNewPassword = !showNewPassword">
        </v-text-field>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text="text" @click="close()">Close</v-btn>
        <v-btn color="primary" text @click="update"> Update </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import userService from "../service";
import { mapGetters } from "vuex";
const { validationMixin } = require("vuelidate");
const { required, minLength } = require("vuelidate/lib/validators");

export default {
  props: ["UserId"],
  data() {
    return {
      showOldPassword: false,
      showNewPassword: false,
      dialog: false,
      User: null,
      oldPassword: null,
      newPassword: null,
    };
  },
  watch: {
    dialog(val) {
      if (val) {
        this.getUser();
      }
    },
  },
  mixins: [validationMixin],
  validations: {
    oldPassword: {
      minLength: minLength(4),
    },
    newPassword: {
      required,
      minLength: minLength(4),
    },
  },
  computed: {
    ...mapGetters("global", ["currentUser"]),
    oldPasswordErrors() {
      const errors = [];
      if (!this.$v.oldPassword.$dirty) return errors;
      if (!this.$v.oldPassword.required)
        errors.push(this.$t("validations.fieldIsRequired"));
      if (!this.$v.oldPassword.minLength)
        errors.push(this.$t("validations.fieldMustbeatLeast4ChrLong"));
      return errors;
    },
    newPasswordErrors() {
      const errors = [];
      if (!this.$v.newPassword.$dirty) return errors;
      if (!this.$v.newPassword.required)
        errors.push(this.$t("validations.fieldIsRequired"));
      if (!this.$v.newPassword.minLength)
        errors.push(this.$t("validations.fieldMustbeatLeast4ChrLong"));
      return errors;
    },
  },
  methods: {
    close() {
      this.User = null;
      this.dialog = false;
    },
    update() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$swal
          .mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          })
          .fire({
            icon: "error",
            title: this.$t("toastMessage.text.invalidFields"),
          });
      } else {
        let data = {
          oldPassword: this.oldPassword,
          newPassword: this.newPassword,
        }
        if (this.currentUser.superAdmin) {
          data.superAdmin = true
        }

        return userService
          .changePassword(this.User.id, data)
          .then((result) => {
            if (result.status) {
              this.$swal
                .mixin({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                })
                .fire({
                  icon: "success",
                  title: "Password Change!",
                });
              this.loading = false;
              this.$router.push("/logout");
            }
          })
          .catch((err) => {
            // console.log("error", err);
            this.loading = false;
            this.$swal
              .mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
              })
              .fire({
                icon: "error",
                title: err.data.message,
              });
          });
      }
    },
    getUser() {
      return userService.getById(this.UserId).then((response) => {
        this.User = response.data;
        // console.log('user', this.User)
        // console.log('currentUser', this.currentUser)
      });
    },
  },
};
</script>
