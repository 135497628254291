<template>
  <router-view></router-view>
</template>
<script>
import RegisterStoreModule from '@/mixins/RegisterStoreModule'
import userStore from './store.js'

export default {
  name: 'users',
  mixins: [RegisterStoreModule],
  created() {
    this.registerStoreModule('user', userStore)
  },
}
</script>
